import React, { useState, useEffect, useRef } from 'react'
import shortid from 'shortid'
import { useLocation } from '@reach/router'
import { Box, NavLink } from '@theme-ui/components'
import slugify from 'slugify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import LinkSelector from '../../../../Links/LinkSelector'
// import addScrollToIDEventListeners from '../../../../../helpers/addScrollToIDEventListeners'

export default function DropdownNavItemV1({ navLinks, variantName, isMultiLocationSite, city, index }) {
  // ===== state =====
  const [dropdownOpen, toggleDropdown] = useState(false)
  const [currentPath, setCurrentPath] = useState(useLocation().pathname)

  // =====  variables transfrmations  =====
  const variant = variantName ? variantName : 'navItemDropdownV1'
  const { ctaName, ctaLink, className, dropdownLinks } = navLinks
  const tagName = `${slugify(ctaName, { lower: true })}${index}`
  const dropdownRef = useRef() // the ref is used so the event listener has access to the latest state
  dropdownRef.current = dropdownOpen

  useEffect(() => {
    const handleClick = e => {
      const navItemDropdown = document.getElementById(`${tagName}Dropdown`)
      const navItemLabel = document.getElementById(`${tagName}`)
      const withinBoundaries = e.composedPath().includes(navItemDropdown)

      // check if click in the dropdown
      if (withinBoundaries) {
        // if the click is the label toggle the dropdown else do nothing
        // this is to prevent the dropdown closing if the navlink is clicked
        if (e.composedPath().includes(navItemLabel)) {
          toggleDropdown(!dropdownRef.current)
        }
      } else {
        if (dropdownRef.current === true) {
          toggleDropdown(false) // if the click isn't in the nav dropdown close it
        }
      }
    }
    // addScrollToIDEventListeners(dropdownLinks, currentPath)
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  return (
    <Box variant={`${variant}.navItemDropdown`} id={`${tagName}Dropdown`} className="navItemDropdown">
      <NavLink
        as="li"
        className={`navItem ${tagName} ${className ? `${className}NavItem` : ''}`}
        variant={`${variant}.navItem`}
        id={`${tagName}`}
        key={shortid.generate()}
      >
        <a className="navItemLink">
          {ctaName} <FontAwesomeIcon icon={faCaretDown} />
        </a>
      </NavLink>
      {dropdownOpen && (
        <Box variant={`${variant}.navItemDropdownMenu`} className="navItemDropdownMenu">
          {dropdownLinks.map(dropdownLink => {
            const {
              ctaName,
              ctaLink,
              className,
              dropdownLinks, // in the future we can do nested dropdowns within dropdowns with a new component that loops
              scrollToID
            } = dropdownLink
            return (
              <NavLink
                as="li"
                className={`navItem ${slugify(ctaName, {
                  lower: true
                })} ${className ? `${className}NavItem` : ''}`}
                variant={`${variant}.subNavItemDropdown`}
                key={shortid.generate()}
              >
                {scrollToID ? (
                  <a id={`navLink-${slugify(ctaName, { lower: true })}`}>{ctaName}</a>
                ) : (
                  <LinkSelector
                    variant={variant}
                    ctaName={ctaName}
                    ctaLink={ctaLink}
                    isMultiLocationSite={isMultiLocationSite}
                    city={city}
                    ctaClassName={className}
                  />
                )}
              </NavLink>
            )
          })}
        </Box>
      )}
    </Box>
  )
}
